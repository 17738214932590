import axios from "axios";
import { TOKEN } from "@/utils/constants";

const token = localStorage.getItem(TOKEN);
const header = { Authorization: `Bearer ${token}` };
const baseURL = process.env.VUE_APP_BASE_URL;

export function createRepratriation(payload) {
  return axios.post(
    `${baseURL}/repataition/create`,
    { ...payload },
    { headers: header }
  );
}

export function listRepriatration() {
  return axios.get(`${baseURL}/repataition/list`, { headers: header });
}
