<template>
  <div class="flex overflow-hidden">
    <div class="py-8 pr-7 w-full">
      <div class="flex justify-between items-center">
        <h1 class="text-2xl font-bold">Repatriation</h1>
        <button
          @click="overlay = true"
          class="bg-primary py-4 px-7 rounded-xl shadow-lg flex items-center font-bold text-white"
        >
          <plus class="mr-5" />
          New Repatriation
        </button>
      </div>
      <div class="" v-if="items.length !== 0">
        <repatriation-table
          :allRepriatration="items"
          :perPage="perPage"
          :total="total"
          :currentPage="currentPage"
          @fetchPage="fetchInventory"
        />
      </div>
      <div class="h-full w-full flex items-center justify-center" v-else>
        <Spinner />
      </div>
    </div>
    <add-new-repatriation
      v-if="overlay"
      @close="overlay = false"
      @created="
        overlay = false;
        fetchInventory();
      "
    />
  </div>
</template>

<script>
import { listRepriatration } from "@/services/repriatration";

export default {
  name: "Inventory",
  components: {
    plus: () => import("@/assets/icons/PlusIcon.vue"),
    Spinner: () => import("@/components/Base/Spinner.vue"),
    AddNewRepatriation: () =>
      import("@/components/Repatriation/AddNewRepatriation.vue"),
    RepatriationTable: () =>
      import("@/components/Repatriation/RepatriationTable.vue"),
  },
  data() {
    return {
      searchTerm: "",
      overlay: false,
      items: [],
      perPage: 0,
      currentPage: 0,
      total: 0,
    };
  },
  methods: {
    async fetchInventory(page = 1) {
      try {
        const repataitions = await listRepriatration(page);
        this.items = repataitions.data.repataition.data;
        this.currentPage = repataitions.data.repataition.current_page;
        this.perPage = repataitions.data.repataition.per_page;
        this.total = repataitions.data.repataition.total;
      } catch (error) {
        this.$toast.error(error.response.data.message || error);
        console.log(error);
      }
    },
  },
  mounted() {
    this.fetchInventory();
  },
};
</script>
